import React, {useEffect, useState} from "react";
import {changeOverlayContent} from "events/OverlayEvents";
import {API_ROOT} from "config/Parameters";
import "./GreenlyPopUp.scss";

const GreenlyPopUp = (props) => {
    const {setPopupVisible} = props;

    const close = (e) => {
        e.preventDefault();
        localStorage.setItem("greenly2025Hidden", true);
        changeOverlayContent(null);
        setPopupVisible(false);
    };

    return (
        <div id="greenly-popup">
            <div id="greenly-popup-close" onClick={(e) => close(e)}>
                <i className="fal fa-times close-icon"></i>
            </div>

            <div id="greenly-popup-header-image">
                <img src="img/greenly/header-2025.png"/>
            </div>

            <div className="greenly-popup-header">Participez au Bilan Carbone Synchrone :</div>
            <div className="greenly-popup-header bold">mesurez votre empreinte écologique !</div>

            <p className="greenly-popup-content">
                <span
                    className="bold">Prenez 5 minutes pour répondre à l'enquête anonyme de notre partenaire GREENLY.</span> Vos
                réponses seront analysées puis intégrées au bilan carbone global de l'entreprise.
            </p>

            <a href="https://bit.ly/Enquete-BilanCarbone_Synchrone-2025" target="_blank" className="greenly-popup-link">Je réponds à l'enquête</a>

            <div id="greenly-popup-footer-image">
                <img src="img/greenly/footer-2025.png"/>
                {/*<div>Pour récompenser votre implication, un tirage au sort* sera réalisé parmi les répondants à*/}
                {/*    l'enquête.*/}
                {/*</div>*/}
                {/*<div></div>*/}
            </div>

            <div className="greenly-popup-footer"><span className="greenly-popup-warning bold">*Attention :</span> <span className="greenly-popup-footer-content">l'enquête est anonyme. Pour participer au tirage au sort, envoyez la capture écran de
                votre enquête soumise à :</span> <span className="bold underline">rse@synchrone.fr</span>
            </div>
        </div>
    );
};

export default GreenlyPopUp;
