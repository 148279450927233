import React, {useEffect, useState} from "react";
import "./GreenlyLink.scss";
import {changeOverlayContent} from "../../events/OverlayEvents";
import GreenlyPopUp from "../HomepageContainer/GreenlyPopUp/GreenlyPopUp";

const GreenlyLink = (props) => {
    const {setPopupVisible} = props

    const openPopup = () => {
        changeOverlayContent(<GreenlyPopUp setPopupVisible={setPopupVisible} />);
        setPopupVisible(true);
        localStorage.removeItem("greenly2025Hidden");
    };

    return <div onClick={openPopup} id="greenly-link"><img src="/img/greenly/btn-2025.png"/></div>
}

export default GreenlyLink;
